import React, { useState } from 'react';
import { Form, Input, Button, Alert } from 'antd';

interface LoginFormProps {
  // eslint-disable-next-line no-unused-vars
  onLogin: (email: string, password: string, twoFactorCode?: string) => void;
  requires2fa: boolean;
  loginError: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin, requires2fa, loginError }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [twoFactorCode, setTwoFactorCode] = useState<string>('');

  const handleSubmit = () => {
    onLogin(email, password, requires2fa ? twoFactorCode : undefined);
  };

  return (
    <div className="asq-chat-login-form">
      <h2>Login</h2>
      {loginError && <Alert message={loginError} type="error" showIcon />}
      <Form onFinish={handleSubmit}>
        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>

        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
        </Form.Item>

        {requires2fa && (
          <Form.Item label="2FA Code" name="twoFactorCode" rules={[{ required: true, message: 'Please input your 2FA code!' }]}>
            <Input value={twoFactorCode} onChange={(e) => setTwoFactorCode(e.target.value)} />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" className="wide-button">
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginForm;
