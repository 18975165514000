import React, { Component, ErrorInfo, ReactNode } from 'react';
// import '../../Chatbot.css';

interface ErrorBoundaryProps {
  children?: ReactNode; // Ensure children is optional and can be ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null; // More detailed state to capture an Error object
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can log the error to an error reporting service
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="asq-app-error-container">
          <h3 className="asq-app-error-title">Unrecoverable App Error:</h3>
          <p className="asq-app-error-message">{this.state.error ? this.state.error.message : 'Unknown Error'}</p>
        </div>
      );
    }

    // Fallback to children if no error
    return this.props.children ? this.props.children : null; // Ensuring ReactNode is returned
  }
}

export default ErrorBoundary;
