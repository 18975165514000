// ThemeLoader.tsx
import React, { useEffect } from 'react';
import { appConfig } from '../../utils/config';

const ThemeLoader: React.FC = () => {
  useEffect(() => {
    const head = document.getElementsByTagName('head')[0];
    const themeLink = document.createElement('link');
    const compactLink = document.createElement('link');

    themeLink.rel = 'stylesheet';
    themeLink.id = 'theme-link';
    compactLink.rel = 'stylesheet';
    compactLink.id = 'compact-link';

    if (!appConfig.uiParams.theme) appConfig.uiParams.theme = 'light';

    if (appConfig.uiParams.theme.includes('light')) {
      themeLink.href = '/css/themes/light-theme.css';
    } else if (appConfig.uiParams.theme.includes('dark')) {
      themeLink.href = '/css/themes/dark-theme.css';
    }

    if (appConfig.uiParams.theme.includes('compact')) {
      compactLink.href = '/css/themes/compact-theme.css';
    }

    head.appendChild(themeLink);
    head.appendChild(compactLink);

    return () => {
      head.removeChild(themeLink);
      head.removeChild(compactLink);
    };
  }, []);

  return null;
};

export default ThemeLoader;
