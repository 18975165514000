import React, { useState, useEffect } from 'react';
import { CloseOutlined, ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
// import '../../Chatbot.css';
import { appConfig } from '../../utils/config';
import { ChatHeaderProps } from '../../types/ChatTypes';

const ChatHeader: React.FC<ChatHeaderProps> = ({ sessionState }) => {
  // Handle updating the fullscreen class and notifying the parent window
  const [isFullScreen, setFullScreen] = useState<boolean>(appConfig.uiParams.fullscreen ?? false);

  const closeChatWindow = () => {
    window.parent.postMessage('closeChatWindow', '*');
  };

  const updateChatContainerClass = () => {
    const chatbotContainer = document.getElementsByClassName('asq-chat-app-container')[0];
    if (isFullScreen) {
      window.parent.postMessage('fullScreenChat', '*');
      chatbotContainer?.classList.add('asq-chat-app-container-full');
    } else {
      window.parent.postMessage('windowedChat', '*');
      chatbotContainer?.classList.remove('asq-chat-app-container-full');
    }
  };

  const toggleFullScreen = () => {
    setFullScreen(!isFullScreen);
  };

  useEffect(() => {
    updateChatContainerClass();
  }, [isFullScreen]);

  useEffect(() => {
    const chatbotContainer = document.getElementsByClassName('asq-chat-app-container')[0];
    if (isFullScreen) {
      window.parent.postMessage('fullScreenChat', '*');
      chatbotContainer?.classList.add('asq-chat-app-container-full');
    } else {
      window.parent.postMessage('windowedChat', '*');
      chatbotContainer?.classList.remove('asq-chat-app-container-full');
    }
  }, [isFullScreen]);

  // Toggle fullscreen based on configuration or a change in state
  useEffect(() => {
    if (appConfig.uiParams.fullscreen !== undefined) {
      setFullScreen(appConfig.uiParams.fullscreen);
    }
  }, [appConfig.uiParams.fullscreen]);

  const indicatorColorClass = `asq-chat-header-session${sessionState}`;
  const sessionStateTitle = sessionState.charAt(0).toUpperCase() + sessionState.slice(1).replace(/-/g, ' ');
  let headerTitle = appConfig.uiParams.title;
  if (appConfig.apiParams.debugOn) headerTitle += ' (' + String(appConfig.apiParams.userId).slice(0, 8) + ')';
  return (
    <div className="asq-chat-header">
      <span className="asq-chat-header-title">
        <span className={`asq-chat-header-cirlce ${indicatorColorClass}`} title={sessionStateTitle}></span>
        {headerTitle}
      </span>
      <div className="asq-chat-header-buttons">
        <Button
          className="ask-chat-header-button"
          icon={isFullScreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
          shape="circle" // icon shape
          onClick={toggleFullScreen}
        />
        <Button className="ask-chat-header-button" icon={<CloseOutlined />} shape="circle" onClick={closeChatWindow} />
      </div>
    </div>
  );
};

export default ChatHeader;
