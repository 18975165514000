import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/base.css';
import ErrorBoundary from './components/ErrorBoundary/AppErrorBoundary';
import App from './App';
import { appConfig } from './utils/config';
import { log } from './utils/logger';

// Declare a root variable for later use
let root: ReactDOM.Root | null = null;
// Function to initialize the React app
function initializeReactApp() {
  if (root === null) {
    root = ReactDOM.createRoot(document.getElementById('asq-chat-root') as HTMLElement);
    root.render(
      <React.StrictMode>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </React.StrictMode>,
    );
  }
}

if (appConfig.scriptParams.apiKey) {
  initializeReactApp();
} else {
  window.addEventListener('message', (event) => {
    if (event.data.type === 'INIT_PARAMS') {
      appConfig.scriptParams = event.data.data;
      log('info', 'Received scriptParams from loader', appConfig.scriptParams);
      initializeReactApp();
    }
  });
}

// Optional: Initialize the React app with default config if not message received after a timeout
setTimeout(() => {
  if (!appConfig.scriptParams || Object.keys(appConfig.scriptParams).length === 0) {
    abortReactApp('No config received. Can not start the app, aborting...');
  }
}, 2000);

function abortReactApp(msg: string) {
  const rootElement = document.getElementById('asq-chat-root');
  if (rootElement) {
    rootElement.innerHTML = `
      <div class="asq-app-error-container">
        <h3 class="asq-app-error-title">Unrecoverable App Error:</h3>
        <p class="asq-app-error-message">${msg}</p>
      </div>
    `;
  }
}
