// logger.ts
import { appConfig } from './config';
// import { logClientEvent } from './api';

// Function to log messages and post errors, warnings and info to remote
// If debug is on all messages are shown on console and logged to remote
// Else only errors are logged to remote
const log = async (...args: any[]) => {
  const logType = args[0];
  args = args.slice(1); // Remove type from args array

  const debugOn = appConfig.apiParams.debugOn || appConfig.scriptParams.debugOn;

  // Get the current timestamp
  const now = new Date();
  const timestamp = now.toLocaleString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });

  // Prepend the timestamp to the log message
  args.unshift(timestamp);

  // const logMessage = args.map((arg) => (typeof arg === 'object' ? JSON.stringify(arg) : arg)).join(' ');
  switch (logType) {
    case 'error':
      // await logClientEvent(logType, logMessage); // Log errors to remote
      console.error(...args); // Print errors in red
      break;
    case 'warning':
      if (debugOn) {
        // await logClientEvent(logType, logMessage); // Log warnings to remote only if debug is on
        console.warn(...args); // Print warnings in orange
      }
      break;
    case 'info':
      if (debugOn) {
        // await logClientEvent(logType, logMessage); // Log info to remote only if debug is on
        console.info(...args);
      }
      break;
    default:
      console.error('Bad log() request with type:', logType);
  }
};

export { log };
