// config.ts
import { AppConfig, ApiParams, UiParams } from '../types/AppConfigTypes';
import { log } from './logger';

// Initially define the scriptParams with either real values or placeholders
export const appConfig: AppConfig = {
  scriptParams: window.scriptParams || {},
  apiParams: {
    userId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
    idleTimeoutSeconds: 60,
    sessionTimeoutSeconds: 120,
    refreshTimeoutMinutes: 2,
    autoRelogin: false,
    debugOn: true,
    apiVersion: 'Unknown',
    initialPrompt: '',
  },
  uiParams: {
    title: 'How may I help you?',
    theme: 'light',
    fullscreen: false,
    welcomeMessage: 'Welcome, Ask Anything Now!',
    sampleIntros: '',
    userLoginRequired: false,
    requires2fa: false,
  },
  appParams: {
    sessionState: 'inactive',
    httpStatus: 200,
    userMessage: 'User Message Placeholder',
    debugMessage: 'Debug Message Placeholder',
  },
};

export function updateAppConfig(newApiParams: ApiParams, newUiParams: UiParams): void {
  appConfig.apiParams = newApiParams;
  appConfig.uiParams = newUiParams;
  log('info', 'appConfig: ', appConfig);
}
