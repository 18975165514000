import { logout } from './api'; // Ensure the refresh function is correctly imported from './api'
import { log } from './logger';
import { updateApiState } from './events';
// Definitions for the timer IDs
// eslint-disable-next-line no-undef
let idleTimerId: NodeJS.Timeout | null = null;
// eslint-disable-next-line no-undef
let sessionTimerId: NodeJS.Timeout | null = null;

// Resets whenever there is user activity
export const resetIdleTimer = async (idleTimeoutSeconds: number) => {
  if (idleTimerId) clearTimeout(idleTimerId);
  log('info', `setIdleTimer to ${idleTimeoutSeconds}s`);
  idleTimerId = setTimeout(async () => {
    // updateAppParams('sessionState', 'httpStatus');
    await log('warning', 'User is logged out due to inactivity');
    await logout(); // Perform logout operation
    updateApiState('/logout', 200, {
      success: true,
      data: { sessionState: 'idling' },
      message: 'Your session timedout due to inactivity\nRefresh to continue...',
    });
  }, idleTimeoutSeconds * 1000);
};

// Set once upon login and not reset by activity
export const setSessionTimer = (sessionTimeoutSeconds: number) => {
  log('info', `setSessionTimer to ${sessionTimeoutSeconds}s`);
  if (sessionTimerId) clearTimeout(sessionTimerId);
  sessionTimerId = setTimeout(async () => {
    await log('warning', 'Session timedout, logging user out');
    await logout(); // Assuming logout handles the state update or another function handles it
    updateApiState('/logout', 200, {
      success: true,
      data: { sessionState: 'suspended' },
      message: 'Your session timedout\nRefresh to continue...',
    });
  }, sessionTimeoutSeconds * 1000);
};
// Clear all timers, useful when logging out or in error handling
export const clearTimers = () => {
  if (idleTimerId) clearTimeout(idleTimerId);
  if (sessionTimerId) clearTimeout(sessionTimerId);
  log('info', 'cleared all Timers');
};
