import React, { useState, useEffect } from 'react';
import { ApiOutlined, LoadingOutlined } from '@ant-design/icons';

import Chatbot from './Chatbot';
import LoginForm from './components/LoginForm/LoginForm'; // Import the LoginForm component
import { appConfig, updateAppConfig } from './utils/config';
import { authenticate, logout, getLoginConfig } from './utils/api';
import { log } from './utils/logger';
import ThemeLoader from './components/ThemeLoader/ThemeLoader';

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [loginRequired, setLoginRequired] = useState(false);
  const [requires2fa, setRequires2fa] = useState(false);
  const [sessionState, setSessionState] = useState('inactive');

  useEffect(() => {
    async function initApp() {
      window.parent.postMessage('windowedChat', '*');
      try {
        const loginConfig = await getLoginConfig();
        if (loginConfig.success) {
          setLoginRequired(loginConfig.data.user_login_required);
          setRequires2fa(loginConfig.data.requires_2fa);

          if (!loginConfig.data.user_login_required) {
            // Perform anonymous authentication
            const response = await authenticate();
            if (response.success) {
              updateAppConfig(response.data.apiParams, response.data.uiParams);
              log('info', 'loaded theme: ', appConfig.uiParams.theme);
              setSessionState('active');
              setLoading(false); // Authentication successful, stop loading
            } else {
              throw new Error('Authentication failed: ' + response.message);
            }
          } else {
            setLoading(false); // Stop loading, show login form
          }
        } else {
          throw new Error('Failed to get login configuration: ' + loginConfig.message);
        }
      } catch (error) {
        setError(`${error instanceof Error ? error.message : String(error)}`);
        setLoading(false);
      }
    }

    initApp();

    // Handle unloading
    const handleUnload = () => {
      logout();
    };

    window.addEventListener('beforeunload', handleUnload);

    // Cleanup function for the useEffect
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []); // Ensure this effect runs only once on mount

  const handleLogin = async (email: string, password: string, twoFactorCode?: string) => {
    try {
      const response = await authenticate(email, password, twoFactorCode);
      if (response.success) {
        updateAppConfig(response.data.apiParams, response.data.uiParams);
        setSessionState('active');
        setLoginError(''); // Clear any previous login errors
      } else {
        setLoginError(response.message); // Set login-specific error message
      }
    } catch (error) {
      setLoginError(`${error instanceof Error ? error.message : String(error)}`);
      setSessionState('inactive');
    }
  };

  return (
    <div className="App">
      {loading ? (
        <div className="asq-chat-window-part-screen active asq-chat-status-container">
          <LoadingOutlined style={{ fontSize: '72px', color: '#444', marginBottom: 40 }} />
          <p className="asq-chat-status-message">Connecting to server...</p>
        </div>
      ) : error && sessionState === 'active' ? (
        <div className="asq-chat-status-container">
          <ApiOutlined style={{ fontSize: '72px', color: '#444', marginBottom: 40 }} />
          <p className="asq-chat-status-message">
            Could not authenticate with the server...
            <br />
            <i>
              <small>{error}</small>
            </i>
          </p>
        </div>
      ) : sessionState !== 'active' && loginRequired ? (
        <LoginForm onLogin={handleLogin} requires2fa={requires2fa} loginError={loginError} />
      ) : (
        <>
          <ThemeLoader />
          <Chatbot />
        </>
      )}
    </div>
  );
}

export default App;
