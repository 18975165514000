import React, { useState, useEffect, useCallback, useRef } from 'react';
import DOMPurify from 'dompurify';
import { v4 as uuidv4 } from 'uuid';
import ChatHeader from './components/ChatHeader';
import ChatContent from './components/ChatContent/ChatContent';
import FeedbackForm from './components/FeedbackForm';
import ChatInput from './components/ChatInput/ChatInput';
import { appConfig } from './utils/config';
import { askQuestion } from './utils/api';

import { ChatMessage, StandardResponseModel } from './types/ChatTypes';
import { log } from './utils/logger';
// import './Chatbot.css';

const Chatbot: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [sessionState, setSessionState] = useState(appConfig.appParams.sessionState);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputText, setInputText] = useState<string>('');
  const [isAsking, setIsAsking] = useState(false);
  const [initialPromptProcessed, setInitialPromptProcessed] = useState(false);
  const initialPromptSentRef = useRef(false);

  // Message and alert management
  const addMessage = useCallback(
    (message: ChatMessage) => {
      if (message.isHtml && message.text) {
        // Replace newlines with html breaks
        message.text = message.text.replace(/\\n/g, '<br>');
        // Sanitize HTML content
        message.text = DOMPurify.sanitize(message.text);
      }
      setMessages((prevMessages) => [...prevMessages, message]);
    },
    [setMessages],
  );

  const addAlertMessage = useCallback(
    (type: 'error' | 'warning' | 'info', messageText: string, details?: string) => {
      const className = `asq-chat-alert-message alert-${type}`; // Default class
      if (details && appConfig.scriptParams.debugOn) {
        messageText += ` ${details}`;
      }
      const newAlert: ChatMessage = {
        id: uuidv4(), // unique ID for key purposes
        text: messageText,
        isUser: false,
        isHtml: true,
        className: className,
        timestamp: new Date(),
      };
      addMessage(newAlert);
    },
    [addMessage],
  );

  const handleSendMessage = useCallback(
    async (suggestion?: string) => {
      const question = inputText || suggestion;
      if (!question || !question.trim()) return;
      setIsAsking(true);
      setInputText('');

      const userMessageId = uuidv4();
      const typingMessageId = uuidv4();
      const userMessage = { id: userMessageId, text: question, isUser: true, timestamp: new Date() };
      const typingMessage = { id: typingMessageId, isTyping: true, isUser: false };

      addMessage(userMessage);
      addMessage(typingMessage);

      try {
        const response = await askQuestion(question);
        processResponse(response, typingMessageId);
      } catch (error) {
        handleError(typingMessageId, error);
      } finally {
        setIsAsking(false);
      }
    },
    [inputText, addMessage],
  );

  const processResponse = useCallback(
    (response: StandardResponseModel, typingMessageId: string) => {
      setMessages((prevMessages) =>
        prevMessages.map((msg) => {
          if (msg.id === typingMessageId) {
            return response.success
              ? { ...msg, text: response.data.answer, timestamp: new Date(), isHtml: true, isTyping: false }
              : { ...msg, isTyping: false }; // Assuming you want to remove the message if not successful
          }
          return msg;
        }),
      );

      if (!response.success) {
        log('error', 'Failed to get an answer from AI', response.data);
        addAlertMessage('error', response.message);
      }
    },
    [setMessages, addAlertMessage],
  );

  const handleError = useCallback(
    (typingMessageId: string, error: any) => {
      setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== typingMessageId));
      const message = `${error instanceof Error ? error.message : String(error)}`;
      addAlertMessage('error', message);
      log('error', message);
      // resetChat();
    },
    [setMessages, addAlertMessage],
  );

  useEffect(() => {
    const handleSessionStateChange = (event: CustomEvent) => {
      log('info', 'Event detail received in chat header: ', event.detail);
      setSessionState(event.detail.sessionState);
      const userMessage = appConfig.appParams.userMessage;
      if (userMessage !== '') addAlertMessage('error', userMessage);
    };

    document.addEventListener('chat-session-state-change', handleSessionStateChange as EventListener);
    return () => {
      document.removeEventListener('chat-session-state-change', handleSessionStateChange as EventListener);
    };
  }, [addAlertMessage]);

  // Reset chat and load params upon chat init
  useEffect(() => {
    resetChat();
  }, []);

  useEffect(() => {
    if (initialPromptSentRef.current) return;

    resetChat();
    if (!initialPromptProcessed && appConfig.apiParams.initialPrompt) {
      const sendInitialPrompt = async () => {
        setIsAsking(true);
        const typingMessageId = uuidv4();
        const typingMessage = { id: typingMessageId, isTyping: true, isUser: false };
        addMessage(typingMessage);

        try {
          const response = await askQuestion(appConfig.apiParams.initialPrompt);
          processResponse(response, typingMessageId);
        } catch (error) {
          handleError(typingMessageId, error);
        } finally {
          setIsAsking(false);
          setInitialPromptProcessed(true);
          initialPromptSentRef.current = true;
        }
      };

      sendInitialPrompt();
    }
  }, [initialPromptProcessed, handleError, processResponse]);

  function resetChat() {
    log('info', 'Resetting resetChat');
    setMessages([]);
    addMessage({
      id: uuidv4(),
      text: appConfig.uiParams.welcomeMessage,
      isUser: false,
      timestamp: new Date(),
    });
  }

  return (
    <div className="asq-chat-app-container">
      <ChatHeader sessionState={sessionState} />
      <ChatContent messages={messages} handleSendMessage={handleSendMessage} />
      <ChatInput inputText={inputText} setInputText={setInputText} isAsking={isAsking} handleSendMessage={handleSendMessage} />
      <FeedbackForm addAlertMessage={addAlertMessage} isModalOpen={isModalVisible} setIsModalOpen={setIsModalVisible} />
    </div>
  );
};

export default Chatbot;
