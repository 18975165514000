import React, { useEffect, useRef } from 'react';
import { ChatInputProps } from '../../types/ChatTypes';
import { SendOutlined } from '@ant-design/icons';

const ChatInput: React.FC<ChatInputProps> = ({ inputText, setInputText, handleSendMessage, isAsking }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isAsking && inputText.trim()) {
      event.preventDefault(); // Prevent form submission
      handleSendMessage();
    }
  };
  // Focus the input element whenever `isAsking` changes back to false,
  // indicating a message has just been sent and the app is ready for more input.
  useEffect(() => {
    if (!isAsking && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAsking]);

  return (
    <div className="asq-chat-input">
      <input
        type="text"
        ref={inputRef}
        value={inputText}
        onChange={handleUserInput}
        onKeyDown={handleKeyDown}
        placeholder="Type your message..."
        autoFocus
        readOnly={isAsking}
      />
      <button disabled={!inputText || isAsking} onClick={handleSendMessage}>
        {isAsking ? '•••' : <SendOutlined />}
      </button>
    </div>
  );
};

export default ChatInput;
