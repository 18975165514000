import { appConfig, updateAppConfig } from './config';
import { updateApiState } from './events';
import { resetIdleTimer, clearTimers, setSessionTimer } from './timers';
import { StandardResponseModel, UpdateUserRequest } from '../types/ChatTypes';
import { log } from './logger';
/*
api call http error codes:
  HTTP_403_FORBIDDEN : "Customer access disabled",
  HTTP_500_INTERNAL_SERVER_ERROR, "Internal Server Error"
  HTTP_429_TOO_MANY_REQUESTS, "Customer session limit reached" OR Limiter's ratelimit exceeded
  HTTP_402_PAYMENT_REQUIRED, "Customer chat pack expired or chatpack expiry date over"
  HTTP_401_UNAUTHORIZED, "Missing, bad, tampered or expired token or no api key", /refresh
*/

function createRequestOptions(method: 'GET' | 'POST', data: any = null): RequestInit {
  const headers: HeadersInit = {
    Authorization: `Bearer ${appConfig.scriptParams.apiKey}`,
    'Content-Type': 'application/json',
  };

  const options: RequestInit = {
    method: method,
    credentials: 'include',
    headers: headers,
    body: method === 'POST' && data !== null ? JSON.stringify(data) : undefined,
  };

  return options;
}

async function apiCall(endpoint: string, method: 'GET' | 'POST', data: any = null, isRetry = false): Promise<StandardResponseModel> {
  // Handle idle timer reset
  const idleResetEndpoints: string[] = ['/ask', '/chat_user'];
  if (idleResetEndpoints.includes(endpoint)) resetIdleTimer(appConfig.apiParams.idleTimeoutSeconds || 30);

  const url = `${appConfig.scriptParams.serverURL}${endpoint}`;
  const options = createRequestOptions(method, data);
  log('info', `${method} API call to ${url}`);

  try {
    const response = await fetch(url, options);
    const result = await response.json();

    // Update if this is a retry OR status is not unauthorized or autologin is not set
    if (isRetry || response.status != 401 || !appConfig.apiParams.autoRelogin) {
      updateApiState(endpoint, response.status, result);
    }

    if (!response.ok) {
      // Handle different HTTP error statuses
      if (response.status === 401) {
        // Unauthorized (access token/refresh token expiry)
        // Handle re-authentication
        if (appConfig.apiParams.autoRelogin && !isRetry) {
          await login();
          log('info', 'Relogin on idle or session timeout.');
          return apiCall(endpoint, method, data, true);
        } else {
          log('warning', 'Access denied, relogin not attempted.');
          clearTimers();
          return { success: false, data: { http_status: response.status }, message: 'Session timed out. Reload page to restart' };
        }
      } else if (response.status === 429) {
        // Too many requests - session limit or flooding
        // Handle rate limit exceeded
        log('warning', 'Access denied, Too many requests!');
        clearTimers();
        return { success: false, data: { http_status: response.status }, message: 'Too many requests. Please try again later.' };
      }

      const message = result.message || 'Server error';
      log('error', `${endpoint}: ${message}, status: ${response.status}`);
      return { success: false, message: message, data: { http_status: response.status } };
    }

    return result;
  } catch (error: unknown) {
    console.error('Error during API call to ' + endpoint, error);
    // Determining the type of error and its message
    let message: string;
    if (error instanceof Error) {
      message = error.message;
    } else if (typeof error === 'string') {
      message = error;
    } else if (error instanceof TypeError) {
      message = 'Network error: ' + error.message; // More specific handling for network errors
    } else {
      message = 'An unknown error occurred in the API call';
    }

    // Logging the error with specifics about the endpoint and the error message
    log('error', `${endpoint}: ${message}, status: Exception`);

    // Updating the application state to reflect the API call failure
    updateApiState(endpoint, 500, { success: false, data: {}, message: `Internal server error: ${message}` });

    // Returning a structured error response
    return { success: false, data: { http_status: 500 }, message: message };
  }
}

const ping = async (): Promise<StandardResponseModel> => {
  if (!navigator.onLine) {
    const message = 'Network down. Please check your internet connection.';
    log('warning', message);
    return {
      success: false,
      data: { http_status: 503 },
      message: message,
    };
  }
  const result = await apiCall('/ping', 'GET');
  return result;
};

const getLoginConfig = async (): Promise<StandardResponseModel> => {
  const result = await apiCall('/login_config', 'GET');
  return result;
};

const login = async (email?: string, password?: string, twoFactorCode?: string): Promise<StandardResponseModel> => {
  const loginData: any = {};
  if (email) loginData.email = email;
  if (password) loginData.password = password;
  if (twoFactorCode) loginData.two_factor_code = twoFactorCode;

  const result = await apiCall('/login', 'POST', loginData);
  clearTimers();

  if (result.success) {
    updateAppConfig(result.data.apiParams, result.data.uiParams);
    appConfig.appParams.sessionState = 'active';
    setSessionTimer(appConfig.apiParams.sessionTimeoutSeconds || 61);
    resetIdleTimer(appConfig.apiParams.idleTimeoutSeconds || 31);
  }

  return result;
};

const authenticate = async (email?: string, password?: string, twoFactorCode?: string): Promise<StandardResponseModel> => {
  const pingResult = await ping();
  if (pingResult.success) {
    const loginResult = await login(email, password, twoFactorCode);
    clearTimers();

    if (loginResult.success) {
      updateAppConfig(loginResult.data.apiParams, loginResult.data.uiParams);
      appConfig.appParams.sessionState = 'active';
      setSessionTimer(appConfig.apiParams.sessionTimeoutSeconds || 61);
      resetIdleTimer(appConfig.apiParams.idleTimeoutSeconds || 31);
    }

    return loginResult;
  } else {
    return pingResult;
  }
};

const refresh = async (): Promise<StandardResponseModel> => {
  const result = await apiCall('/refresh', 'POST', {});
  return result;
};

const logout = async (): Promise<StandardResponseModel> => {
  const result = await apiCall('/logout', 'POST', {});
  return result;
};

const askQuestion = async (question: string): Promise<StandardResponseModel> => {
  const result = await apiCall('/ask', 'POST', { question });
  return { success: result.success, message: result.message, data: result.data };
};

const updateUser = async (userData: UpdateUserRequest): Promise<StandardResponseModel> => {
  const result = await apiCall('/chat_user', 'POST', userData);
  return result;
};

// --eslint-disable-next-line no-unused-vars
const logClientEvent = async (type: 'info' | 'warning' | 'error', message: string): Promise<void> => {
  if (!appConfig.apiParams.debugOn && type != 'error') return;
  const url = `${appConfig.scriptParams.serverURL}/client_event_log`;
  const options = createRequestOptions('POST', { message: message, type: type });
  try {
    await fetch(url, options);
  } catch (error: unknown) {
    console.error('Error logging client event:', error instanceof Error ? error.message : error);
  }
};

export { getLoginConfig, authenticate, askQuestion, logout, refresh, updateUser, logClientEvent };
export type { StandardResponseModel };
