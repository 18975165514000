import { SessionState } from '../types/AppConfigTypes';
import { appConfig } from './config';
import { log } from './logger';

// Additional event-related functions can be here
/**
 * Sets the session state based on the provided endpoint, status code and result.
 * @param {string} endpoint - The API endpoint.
 * @param {number} status - The HTTP status code.
 * @param {any} result - JSON result of the request.
 */
export function updateApiState(endpoint: string, status: number, result: any) {
  const endpoints = ['/login', '/refresh', '/logout', '/ask', '/chat_user'];
  if (!endpoints.includes(endpoint)) {
    return;
  }
  const debugOn = appConfig.apiParams.debugOn;
  const autoRelogin = appConfig.apiParams.autoRelogin;

  let newSessionState: SessionState = 'inactive'; // Define a variable to hold the new session state
  let debugMessage: string = '';
  let userMessage: string = '';

  if (endpoint === '/logout') {
    if (result.data && result.data.sessionState) {
      newSessionState = result.data.sessionState;
      if (debugOn || !autoRelogin) userMessage = result.message;
    } else {
      newSessionState = 'inactive';
      //  if (debugOn || !autoRelogin) userMessage = 'Your session has ended.';
    }
  } else {
    // Session states = 'inactive' | 'active' | 'flooded' | 'suspended' | 'expired' | 'error' | 'not-allowed' | 'idling';
    // idling is set before calling logout in the idle timeout timer
    switch (status) {
      case 200: // Ok
        newSessionState = 'active'; // User logged in
        userMessage = '';
        break;
      case 429: // Too many requests
        newSessionState = 'flooded'; // Max concurrent sessions for customer exceeded or flooding
        userMessage = 'Too many requests.\nPlease try after some time...';
        break;
      case 401: // Unauthorized
        newSessionState = 'suspended'; // Invalid access token
        if (debugOn || !autoRelogin) userMessage = 'Your session is suspended.\nRefresh to retry.';
        break;
      case 403: // Forbidden
        newSessionState = 'expired'; // Refresh token expired
        userMessage = 'Your session has expired.\nRefresh to start a new session.';
        break;
      case 500: // Internal error
        userMessage = 'Oops! We have hit a snag.\nPlease try again later...';
        newSessionState = 'error';
        break;
      case 402: // Payment Required
        userMessage = 'Service temporarily discontinued.\nPlease contact customer service.';
        newSessionState = 'not-allowed'; // past expiry date or chat pack expired
        break;
      default:
        debugMessage = `Unknown status code to updateApiState: ${status}`;
        userMessage = 'Looks like we have made a booboo!\nPlease try a little later.';
        newSessionState = 'error';
        break;
    }
  }

  // Update appConfig.appParams.session
  debugMessage = `${endpoint} status: ${status}, success: ${result.success}\nmessage: ${result.message}`;
  if (userMessage) debugMessage += '\nuMsg: ' + userMessage;
  appConfig.appParams.sessionState = newSessionState;
  appConfig.appParams.httpStatus = status;
  appConfig.appParams.userMessage = userMessage;
  appConfig.appParams.debugMessage = debugMessage;
  log('info', debugMessage);

  const event = new CustomEvent('chat-session-state-change', { detail: { sessionState: newSessionState } });
  document.dispatchEvent(event);
  log('info', `Event dispatched, sessionState: ${newSessionState}`);
}
