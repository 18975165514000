import { FC } from 'react';
// import '../../Chatbot.css';
import { AskingQuestionProps } from '../../types/ChatTypes';

const AskingQuestion: FC<AskingQuestionProps> = () => {
  const { botMessageBox } = { botMessageBox: { backgroundColor: '#3498db', loadingDotColor: '#444' } };

  return (
    <div className="asq-chat-asking-question">
      <div className="asq-chat-asking-question-dots-container">
        <div
          className="asq-chat-asking-question-dot-0 asq-chat-asking-question-dot-1"
          style={{ backgroundColor: botMessageBox?.loadingDotColor }}
        ></div>
        <div
          className="asq-chat-asking-question-dot-0 asq-chat-asking-question-dot-2"
          style={{ backgroundColor: botMessageBox?.loadingDotColor }}
        ></div>
        <div
          className="asq-chat-asking-question-dot-0 asq-chat-asking-question-dot-2"
          style={{ backgroundColor: botMessageBox?.loadingDotColor }}
        ></div>
      </div>
    </div>
  );
};

export default AskingQuestion;
