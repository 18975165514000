import React, { useState, useEffect } from 'react';
import { Rate, Form, Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
// import '../../Chatbot.css';
import { updateUser } from '../../utils/api';
import { FeedbackFormProps } from '../../types/ChatTypes';
import { log } from '../../utils/logger';

const FeedbackForm: React.FC<FeedbackFormProps> = ({ addAlertMessage, isModalOpen, setIsModalOpen }) => {
  const [rating, setRating] = useState(0);
  const desc = ['Unsatisfactory', 'Poor', 'Acceptable', 'Great', 'Excellent'];

  async function handleSaveRating(value: number) {
    if (value > 0) {
      try {
        const response = await updateUser({ rating: value });
        if (response.success) {
          log('info', `Ratings: ${value} stars`);
          setRating(value);
        } else {
          log('error', `Error processing question: ${response.message}`);
          addAlertMessage('error', `Error: ${response.message}`);
        }
      } catch (error) {
        log('error', 'Error saving rating: ', error);
      }
    }
  }

  const closeFeedbackForm = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (rating > 0) {
      const timer = setTimeout(() => {
        closeFeedbackForm();
        setRating(0);
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [rating]);

  return (
    <Drawer
      title="Rate Your Experience"
      placement="bottom"
      className="asq-chat-feeback-container"
      closable={false}
      onClose={closeFeedbackForm}
      open={isModalOpen}
      maskClosable={false}
      height={200}
      width={200}
      zIndex={9999}
      style={{ position: 'absolute' }}
      extra={<CloseOutlined style={{ cursor: 'pointer' }} onClick={closeFeedbackForm} />}
    >
      <div className="asq-chat-feedback-form-container">
        {rating ? (
          <p className="asq-chat-feedback-thanks-message">Thank you for your feedback!</p>
        ) : (
          <Form>
            <Rate defaultValue={0} className="asq-chat-feedback-star-rating" tooltips={desc} onChange={handleSaveRating} />
          </Form>
        )}
      </div>
    </Drawer>
  );
};

export default FeedbackForm;
